import React from 'react';
import { Loading } from '@carbon/react';

const LoadingPage = ({ title }) => (
  <Loading
    active={true}
    withOverlay={true}
    description={title}
  />
);

export default LoadingPage;
